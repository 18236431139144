import React, { useState, lazy } from 'react';
import { useUser } from "../../../UserContext";
const CustomModal = lazy(() => import("../../Modals"));

export const SubMenu = ({ selected = 'scorecard', setSelected, complete }) => {
  const { user } = useUser();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deeplink, setDeeplink] = useState(window.location.href);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className='relative mt-[10px] mx-0 px-0 max-w w-[100vw] md:w-[100%] bg-header h-[50px] md:h-[60px] text-white border-solid border-[#18062f] border-b-[1px] overflow-x-auto'>
      <div className="flex h-full whitespace-nowrap px-[14px] pt-[8px] font-normal text-white text-[18px]">
        <div style={selected === 'feed' ? {borderBottom: '4px solid #d2ff00'} : {}}  className={`px-[10px] pt-[6px] h-full cursor-pointer`} onClick={() => setSelected('feed')}>FEED</div>
        {complete !== 'true' ? (
          <>
            {!user ? (
              <div style={selected === 'trading' ? {borderBottom: '4px solid #d2ff00'} : {}}  className={`px-[10px] pt-[5px] h-full cursor-pointer`} onClick={openModal}>
                <div className='text-[#a39bac] flex items-center'>ODDS <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm0-2h12V10H6zm6-3q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6zM6 20V10z"/></svg></div>
              </div>
            ) : (
              <div style={selected === 'trading' ? {borderBottom: '4px solid #d2ff00'} : {}} className={`px-[10px] pt-[6px] h-full cursor-pointer`} onClick={() => setSelected('trading')}>
                ODDS
              </div>
            )}
          </>
        ) : (null)}
        <div style={selected === 'scorecard' ? {borderBottom: '4px solid #d2ff00'} : {}} className={`px-[10px] pt-[6px] h-full cursor-pointer`} onClick={() => setSelected('scorecard')}>SCORECARD</div>
        {complete !== 'true' ? (
          <div style={selected === 'commentary' ? {borderBottom: '4px solid #d2ff00'} : {}} className={`px-[10px] pt-[6px] h-full cursor-pointer`} onClick={() => setSelected('commentary')}>COMMENTARY</div>
        ) : (null)}
        <div style={selected === 'charts' ? {borderBottom: '4px solid #d2ff00'} : {}} className={`px-[10px] pt-[6px] h-full cursor-pointer md:hidden`} onClick={() => setSelected('charts')}>CHARTS</div>
        {complete !== 'true' ? (
          <>
            <div style={selected === 'lineup' ? {borderBottom: '4px solid #d2ff00'} : {}} className={`px-[10px] pt-[6px] h-full cursor-pointer`} onClick={() => setSelected('lineup')}>LINEUP</div>
          </>
        ) : ( null )}
        {/* <div className={`px-[10px] pt-[6px] h-[30px] cursor-pointer ${selected === 'videos' ? 'bg-[#d2ff00]' : ''}`} onClick={() => setSelected('videos')}>VIDEOS</div>
        <div className={`px-[10px] pt-[6px] h-[30px] cursor-pointer ${selected === 'news+analysis' ? 'bg-[#d2ff00]' : ''}`} onClick={() => setSelected('news+analysis')}>NEWS & ANALYSIS</div> */}
      </div>
      <CustomModal id='modal' isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Need Account">
          <div>
            <div className='text-[#d2ff00] text-[20px] md:text-[30px] text-center font-anton'>YOU NEED AN ACCOUNT FOR THIS CONTENT</div>
            <div className='mt-[20px] font-chakra text-white text-center text-[14px] md:text-[16px]'>
              Create a free Cricket8 account for access to exclusive content.
            </div>
            <div className='mt-[40px] w-full flex justify-center'>
              <a href={`/signup?back=${deeplink}`}>
                <div className='bg-[#d2ff00] h-[50px] w-[100px] md:w-[265px] text-center'>
                  <div className='font-anton text-[#1b062f] text-[16px] pt-[17px]'>
                    SIGN UP
                  </div>
                </div>
              </a>
            </div>
            <div className='mt-[30px] mb-[40px] w-full justify-center font-chakra text-[14px] md:text-[16px] flex'>
              <div className='text-white pr-[5px]'>Already got an account?</div>
              <div className='text-[#d2ff00]'><a className='text-[#d2ff00] hover:text-underline' href={`/login?back=${deeplink}`}>Login here</a></div>
            </div>
          </div>
      </CustomModal>
    </div>
  );
};

export default SubMenu;
